<template>
    <transition name="fade">
        <div class="media-modal" v-touch:swipe.left="next" v-touch:swipe.right="prev">
            <div class="media-modal__bg" @click="closeModal" :class="{ 'no-bg': true }"></div>
            <img src="../assets/global/icon-arrow-left-alt.svg" class="modal__btn modal__btn--prev" alt = "Click this button to navigate to the previous modal item" @click="prev" v-if="data && data.length > 1">
            <img src="../assets/global/icon-arrow-right-alt.svg" class="modal__btn modal__btn--next" alt = "Click this button to navigate to the next modal item" @click="next" v-if="data && data.length > 1">
            <img src="../assets/global/icon-close2.svg" class="modal__btn modal__btn--close" alt = "Click this button to close the modal" @click="closeModal">

            <transition name="fade">
                <div class="spinner media-modal__loading" v-if="loading">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div>
            </transition>

            <div class="content__wrap" v-if="data && data[index]" :class="{'content__wrap--loading' : loading}">

                <div class="content__wrap__asset" v-if="mediaType == 'image'">
                    <img :src="data[index].asset" @load="onAssetLoaded" ref="imageRef" :alt="data[index].caption.text + ' image'">
                </div>

                <div class="content__wrap__asset" v-else>
                    <iframe :src="'https://www.youtube.com/embed/' + data[index].asset + '?autoplay=1'" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen @load="onAssetLoaded()"></iframe>
                </div>

                <div class="content__wrap__caption" v-if="index != null && data">
                    <div class="content__wrap__caption__credit" v-if="data[index].caption.credit.text">
                        <a :href="data[index].caption.credit.link" target="_blank">
                            <span class="gold">{{ data[index].caption.credit.text }}</span>
                            <span class="dash" v-if="data[index].caption.credit.linkText">-</span>
                            <span class="red" v-if="data[index].caption.credit.linkText">{{ data[index].caption.credit.linkText }}</span>
                        </a>
                    </div>
                    <div class="content__wrap__caption__text" :class="{ 'content__wrap__caption__text--small': data[index].caption.text.length > 75 }">
                        {{ data[index].caption.text }}
                        <div class="content__wrap__caption__text__underline"></div>
                    </div>
                    <div class="content__wrap__caption__share" :style="{ display: $route.path.indexOf('fandom') > -1 ? 'none' : 'block' }">
                        SHARE THIS
                        <div class="content__wrap__caption__share__icons">

                            <!-- make dynamic -->
                            <a :href="twShareURL" target="_blank">
                                <svg viewBox="0 0 62.52 62.52" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m42.41 27.16v.68a15.19 15.19 0 0 1 -15.32 15.32 14.91 14.91 0 0 1 -8.25-2.42 8.1 8.1 0 0 0 1.3.07 10.9 10.9 0 0 0 6.7-2.3 5.41 5.41 0 0 1 -5-3.72 5.58 5.58 0 0 0 1 .12 5.49 5.49 0 0 0 1.43-.18 5.39 5.39 0 0 1 -4.35-5.28v-.06a5.49 5.49 0 0 0 2.42.68 5.47 5.47 0 0 1 -2.42-4.46 5.09 5.09 0 0 1 .75-2.73 15.37 15.37 0 0 0 11.1 5.64 7.78 7.78 0 0 1 -.12-1.24 5.4 5.4 0 0 1 9.31-3.72 10.91 10.91 0 0 0 3.41-1.3 5.61 5.61 0 0 1 -2.36 3 10.13 10.13 0 0 0 3.1-.87 10.34 10.34 0 0 1 -2.7 2.77z" fill="#fff" />
                                    <circle cx="31.26" cy="31.26" fill="none" r="30.26" stroke="#e0c565" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" /></svg>
                            </a>

                            <a :href="fbShareURL" target="_blank">
                                <svg viewBox="0 0 62.52 62.52" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m36.86 30.85h-3.86v13.54h-5.59v-13.54h-2.68v-4.76h2.68v-3.09c0-2.21 1.07-5.7 5.7-5.7h4.16v4.63h-3a1.17 1.17 0 0 0 -1.27 1.27v2.8h4.29z" fill="#fff" />
                                    <circle cx="31.26" cy="31.26" fill="none" r="30.26" stroke="#e0c565" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" /></svg>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </transition>
</template>

<script>
    import Vue from 'vue'
    import router from './../router'
    import SizingHelper from './../helpers/SizingHelper'

    export default {
        props: ['data', 'parentName'],
        components: {
            'media-image': Image
        },
        data() {
            return {
                index: null,
                mediaType: null,
                loading: true,
                scrollTop: 0
            }
        },
        mounted() {
            // console.log(this.data);
            //console.log(this.$route);

            // init right away if data array is present,
            // else it will get called when watcher detects data changed
            if (this.data) {
                this.init();
            }

            this.curScrollTop = this.$window.scrollTop();

            // Note: data is expected as an array in this format
            /* 
                [{
                    "id": 1,
                    "type": "image",
                    "asset": "/img/stories/stories__news__detail--hydra.jpg",
                    "caption": {
                        "text": "This is the caption",
                        "credit": {
                            "text": "@Kirk Flemming",
                            "link": "http://google.com"
                        }
                    }
                }]
            */
        },
        methods: {
            init() {
                this.$body.addClass('modal-showing');
                this.$html.addClass('modal-showing');
                this.addKeyListeners();
                this.changeItem();
                window.addEventListener('resize', this.onResize, false);
                Vue.nextTick(() => {
                    this.sizeAsset();
                });
            },
            addKeyListeners() {
                document.addEventListener("keyup", this.onKeyUp);
            },
            onKeyUp(e) {
                if (e.keyCode == 39) { // right arrow
                    this.next();
                } else if (e.keyCode == 37) { // left arrow
                    this.prev();
                } else if (e.keyCode == 27) { // escape key
                    this.closeModal();
                }
            },
            changeItem() {
                if (!this.data || !this.data.length) return;
                // determine index based on id in url
                this.index = this.data.findIndex(item => item.id == this.$route.params.mediaId);
                if(this.index == -1) return;                
                this.mediaType = this.data[this.index].type;
                if (this.data[this.index].asset != $(this.$refs.imageRef).attr('src')) {
                    this.loading = true;
                }
            },
            next() {
                this.index = this.index == this.data.length - 1 ? this.index = 0 : this.index += 1;
                router.push(this.data[this.index].id.toString());
            },
            prev() {
                this.index = this.index == 0 ? this.data.length - 1 : this.index -= 1;
                this.loading = true;
                router.push(this.data[this.index].id.toString());
            },
            closeModal() {
                router.push({
                    name: this.parentName
                });
            },
            onResize() {
                this.sizeAsset();
            },
            onAssetLoaded() {
                // needs tick or image won't fade in
                Vue.nextTick(() => {
                    this.loading = false;
                });
                this.sizeAsset();
            },
            sizeAsset() {
                let $wrap = $('.content__wrap__asset').filter(':visible');
                let $iframeOrImg = $wrap.find('>:first-child');

                $iframeOrImg.css({
                    width: 'auto',
                    height: 'auto',
                    visibility: 'hidden'
                });

                if (this.mediaType == 'video') {
                    var obj = SizingHelper.calculateAspectRatioFit(16, 9, $wrap.width(), $wrap.height());
                } else {
                    $iframeOrImg.height(10);
                    var obj = SizingHelper.calculateAspectRatioFit($iframeOrImg.width(), $iframeOrImg.height(), $wrap.width(), $wrap.height());
                }
                $iframeOrImg.css({
                    width: obj.width,
                    height: obj.height,
                    visibility: 'visible'
                });
            },
        },
        watch: {
            '$route'(to, from) {
                this.changeItem();
            },
            data: function() {
                this.init();
            }
        },
        computed: {
            fbShareURL() {
                return "https://www.facebook.com/sharer/sharer.php?u=" + encodeURI(window.location.href)
            },
            twShareURL() {
                return "https://twitter.com/share?url=" + encodeURI(window.location.href)
            }
        },
        destroyed() {
            this.$body.removeClass('modal-showing');
            this.$html.removeClass('modal-showing');
            document.removeEventListener("keyup", this.onKeyUp);
            window.removeEventListener('resize', this.onResize);
            this.$window.scrollTop(this.curScrollTop);
        }
    }
</script>